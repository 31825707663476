<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col cols="12">
        <div class="d-flex align-center justify-space-between flex-wrap mb-3">
          <h1 class="mb-0">{{ $t('Dashboard.pageTitle') }}</h1>
          <widgets-components-dashboard-configure v-if="availableWidgets.length > 0" :available-widgets="availableWidgets" :active-widgets="activeWidgets" />
        </div>
        <p v-if="availableWidgetsLoading || activeWidgetsLoading" class="text-center my-10">
          <v-progress-circular
            size="26"
            width="3"
            color="primary"
            class="mr-3"
            indeterminate
          />
          {{ $t('Dashboard.widgetsLoading') }}
        </p>
        <v-row v-else-if="availableWidgets.length > 0">
          <v-col
            v-for="widget in activeWidgets"
            :key="widget.id"
            cols="12"
            :md="widget.width === 'half' ? 6 : 12"
          >
            <widgets-latest-affairs
              v-if="widget.widget_type_id === WIDGET_TYPE_MAP.LATEST_AFFAIRS"
              :widget="widget"
              :affairs="getWidgetData(widget.id)"
              :loading="getWidgetLoading(widget.id)"
            />
            <widgets-latest-comments
              v-if="widget.widget_type_id === WIDGET_TYPE_MAP.LATEST_COMMENTS"
              :widget="widget"
              :comments="getWidgetData(widget.id)"
              :loading="getWidgetLoading(widget.id)"
            />
            <widgets-calendar
              v-if="widget.widget_type_id === WIDGET_TYPE_MAP.CALENDAR"
              :widget="widget"
              :events="getWidgetData(widget.id)"
              :loading="getWidgetLoading(widget.id)"
            />
            <widgets-next-events
              v-if="widget.widget_type_id === WIDGET_TYPE_MAP.NEXT_EVENTS"
              :widget="widget"
              :events="getWidgetData(widget.id)"
              :loading="getWidgetLoading(widget.id)"
            />
          </v-col>
          <v-col
            v-if="activeWidgets.length === 0"
            cols="12"
            align="center"
            class="my-10"
          >
            {{ $t('Dashboard.noActiveWidgets') }}
          </v-col>
        </v-row>
        <p v-else class="text-center my-10">{{ $t('Dashboard.noWidgetsAvailable') }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { WIDGET_TYPE_MAP } from '@/utils/widgets';

export default {
  metaInfo () {
    return {
      title: this.$t('Dashboard.pageTitle'),
    }
  },
  async mounted () {
    await this.getAvailableWidgets()
    await this.getActiveWidgets()
  },
  data () {
    return {
      WIDGET_TYPE_MAP,
    }
  },
  computed: {
    ...mapGetters('widgets', [
      'availableWidgets',
      'availableWidgetsLoading',
      'activeWidgets',
      'activeWidgetsLoading',
      'widgetsData',
      'widgetsLoading',
    ]),
  },
  methods: {
    ...mapActions('widgets', [
        'getAvailableWidgets',
        'getActiveWidgets',
    ]),
    getWidgetData (widgetId) {
      return this.widgetsData[widgetId] || []
    },
    getWidgetLoading (widgetId) {
      return this.widgetsLoading.includes(widgetId)
    },
  },
}
</script>
